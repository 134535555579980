/**
 * @prettier
 */

import { createContext } from 'react';

export type BlogItem = {
	title: string;
	blogid: string;
	image: {
		source: string;
		thumbnail: string;
		full: string;
	};
	imageId?: string;
	tags: string[];
	lastModify: string;
};

export const Context = createContext<{
	showInfoBoard: boolean;
	updateInfoBoard: () => void;
	homePageTab: number;
	updateHomePageTab: (newTab: number) => void;
	blogItem: BlogItem[];
	currentBlogTag: string;
	updateCurrentBlogTag: (newTag: string) => void;
}>({
	showInfoBoard: false,
	updateInfoBoard: () => {},
	homePageTab: 1,
	updateHomePageTab: (_newTab: number) => {},
	blogItem: [],
	currentBlogTag: '',
	updateCurrentBlogTag: (_newTag: string) => {}
});
