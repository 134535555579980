/**
 * @prettier
 */

import ReactDOM from "react-dom/client";
import "./index.css";
import {
  createHashRouter,
  RouterProvider
} from 'react-router-dom';
import Main from "./components/main";

const router = createHashRouter([
  {
    path: "/*",
    element: <Main />,
  }
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <RouterProvider router={router} />
);
