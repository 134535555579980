/**
 * @prettier
 */

import { useState, lazy, Suspense, useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { BlogItem, Context } from './context';

const HomePage = lazy(() => import('./homepage'));
const BlogPage = lazy(() => import('./blogpage'));
const InfoPage = lazy(() => import('./infopage'));
const Loading = () => <p>Loading ...</p>;

const Main = () => {
	const location = useLocation();

	const [showInfoBoard, setShowInfoBoard] = useState<boolean>(false);
	const [homePageTab, setHomePageTab] = useState<number>(1);
	const [blogItem, setBlogItem] = useState<BlogItem[]>([]);
	const [currentBlogTag, setCurrentBlogTag] = useState<string>('');

	const updateInfoBoard = () => {
		const infoBoardStatus = showInfoBoard;
		setShowInfoBoard(!infoBoardStatus);
	};

	const updateHomePageTab = (newTab: number) => setHomePageTab(newTab);
	const updateCurrentBlogTag = (newTag: string) => setCurrentBlogTag(newTag);

	useEffect(() => {
		const blogs = require('../list/blogs.json');
		setBlogItem(blogs);
	}, []);

	return (
		<Context.Provider
			value={{
				showInfoBoard,
				updateInfoBoard,
				homePageTab,
				updateHomePageTab,
				blogItem,
				currentBlogTag,
				updateCurrentBlogTag
			}}>
			<Suspense fallback={<Loading />}>
				<Routes>
					<Route path='/' element={<HomePage tab={homePageTab} />} />
					<Route
						path='/blog/:id'
						element={<BlogPage key={location.pathname} location={location.pathname} />}
					/>
					<Route path='/info' element={<InfoPage />} />
				</Routes>
			</Suspense>
		</Context.Provider>
	);
};

export default Main;
